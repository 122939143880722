import React from 'react';
import logo from "../../images/campego-no-bg-logo.png";

const HomeCardButtons = () => {
    return (
        <div
            className="container d-flex justify-content-center align-items-center flex-column"
            style={{minHeight: '100vh'}}
        >
            <div className="text-center w-100 mb-4">
                <img className="camp-height-300px" src={logo} alt="logo" style={{height: '300px'}}/>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col d-flex align-items-stretch">
                    <div
                        className="card shadow w-100"
                        style={{cursor: 'pointer', transition: 'transform 0.2s ease'}}
                        onClick={() => (window.location.href = '/contact-form')}
                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                        <div className="card-body text-center d-flex flex-column justify-content-center">

                            <div className="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960"
                                     width="72px"
                                     fill="#5f6368">
                                    <path
                                        d="M560-520h280v-200H560v200Zm140-50-100-70v-40l100 70 100-70v40l-100 70ZM80-120q-33 0-56.5-23.5T0-200v-560q0-33 23.5-56.5T80-840h800q33 0 56.5 23.5T960-760v560q0 33-23.5 56.5T880-120H80Zm556-80h244v-560H80v560h4q42-75 116-117.5T360-360q86 0 160 42.5T636-200ZM360-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM182-200h356q-34-38-80.5-59T360-280q-51 0-97 21t-81 59Zm178-280q-17 0-28.5-11.5T320-520q0-17 11.5-28.5T360-560q17 0 28.5 11.5T400-520q0 17-11.5 28.5T360-480Zm120 0Z"/>
                                </svg>
                            </div>
                            <h5 className="card-title">İletişim</h5>
                            <h5 className="card-title">Formu</h5>
                        </div>
                    </div>
                </div>
                <div className="col d-flex align-items-stretch">
                    <div
                        className="card shadow w-100"
                        style={{cursor: 'pointer', transition: 'transform 0.2s ease'}}
                        onClick={() => (window.location.href = '/career-form')}
                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                        <div className="card-body text-center d-flex flex-column justify-content-center">
                            <div className="mb-3">

                                <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960"
                                     width="72px" fill="#5f6368">
                                    <path
                                        d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z"/>
                                </svg>
                            </div>
                            <h5 className="card-title">Kariyer</h5>
                            <h5 className="card-title">Formu</h5>
                        </div>
                    </div>
                </div>
                <div className="col d-flex align-items-stretch">
                    <div
                        className="card shadow w-100"
                        style={{cursor: 'pointer', transition: 'transform 0.2s ease'}}
                        onClick={() => (window.location.href = '/activity-registration-form')}
                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                        <div className="card-body text-center d-flex flex-column justify-content-center">
                            <div className="mb-3">

                                <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960"
                                     width="72px" fill="#5f6368">
                                    <path
                                        d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h360v80H200v560h560v-360h80v360q0 33-23.5 56.5T760-120H200Zm120-160v-80h320v80H320Zm0-120v-80h320v80H320Zm0-120v-80h320v80H320Zm360-80v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Z"/>
                                </svg>
                            </div>
                            <h5 className="card-title">Kayıt</h5>
                            <h5 className="card-title">Formu</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeCardButtons;
