import React from 'react';

const Footer = () => {
    const version = '1.0.0';  // Replace with your version
    const environment = process.env.REACT_APP_ENVIRONMENT || "localhost";

    const footerStyle = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        padding: '10px 0',
        backgroundColor: '#f1f1f1',
        fontSize: '12px',
        color: '#555',
    };

    return (
        <div style={footerStyle}>
            v{version} {environment}
        </div>
    );
};

export default Footer;