const FILE_SIZE_LIMIT_IN_BYTES = (10 * 1048576);

export const submitFormDataAsJsonString = async (formData, apiSubDirectory) => {
    let response;
    try {
        response = await fetch('https://api.campegocamp.com/api/v1' + apiSubDirectory, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(response);
    } catch (error) {
        console.error(error);
    }

    const data = await response;

    return {
        status: data.status,
        text: await data.text()
    };
};

export const submitFormDataAsObject = async (formData, apiSubDirectory) => {
    let response;
    try {
        response = await fetch('https://api.campegocamp.com/api/v1' + apiSubDirectory, {
            method: 'POST',
            body: formData
        });
        console.log(response);
    } catch (error) {
        console.error(error);
    }

    const data = await response;

    return {
        status: data.status,
        text: await data.text()
    };
};

export const createFormData = (file,
                               adminFrom,
                               userFrom,
                               adminEmail,
                               userEmail,
                               adminSubject,
                               userSubject,
                               adminHtmlTemplate,
                               userHtmlTemplate,
                               firstName,
                               lastName,
                               email,
                               phone,
                               city,
                               state,
                               country,
                               message) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('adminFrom', adminFrom);
    formData.append('userFrom', userFrom);
    formData.append('adminEmail', adminEmail);
    formData.append('userEmail', userEmail);
    formData.append('adminSubject', adminSubject);
    formData.append('userSubject', userSubject);
    formData.append('adminHtmlTemplate', adminHtmlTemplate);
    formData.append('userHtmlTemplate', userHtmlTemplate);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('message', message);

    return formData;
};

export const validateTextarea = (textareaInputId, errorMessageId) => {
    const textarea = document.getElementById(textareaInputId);
    const pattern = /^(?!.*(<script|<iframe)\b)[\s\S]*$/;
    const errorMessage = document.getElementById(errorMessageId);
    if (!pattern.test(textarea.value)) {
        errorMessage.style.display = "block";
        return false;
    } else {
        errorMessage.style.display = "none";
        return true;
    }
};

export const validateFileSize = (size, fileInputId, errorMessageId) => {
    const errorMessage = document.getElementById(errorMessageId);
    if (size > FILE_SIZE_LIMIT_IN_BYTES) {
        errorMessage.style.display = "block";
        document.getElementById(fileInputId).value = '';
        return false;
    }
    errorMessage.style.display = "none";
    return true;
};

export const printConsoleLog = (status, message) => {
    console.log(`Status: ${status} , Message: ${message}`)
}

export const redirectPage = (redirectTo) => {
    window.location.href = redirectTo;
}

export const getAdminEmail = () => {
    return "{{_DEFAULT_ADMIN_EMAIL_}}";
}

export const showDialog = (id) => {
    document.getElementById(id).showModal();
}


export const splitMessageContent = (message) => {
    return message.split('\n');
}