import "./Home.css"
import React from "react";
import HomeCardButtons from "./HomeCardButtons";
import Footer from "./Footer";

export default function Home() {
    return (
       <>
           <HomeCardButtons/>
           <Footer/>
       </>
    )
}