import './App.css';
import {Navigate, Route, Routes} from 'react-router-dom';
import ContactForm from "./components/forms/contact-form/ContactForm";
import CareerForm from "./components/forms/career-form/CareerForm";
import Home from "./components/home/Home";
import ActivityRegistrationForm from "./components/forms/activity-registration-form/ActivityRegistrationForm";
import Success from "./components/success/Success";
import Failed from "./components/failed/Failed";
import CampRegisterSuccess from "./components/success/CampRegisterSuccess";
import {useState} from "react";

function Maintenance() {
    return (
        <div className={'container text-center mt-5'}>
            <div>
                <svg width="80px" height="80px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>maintenance</title>
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Combined-Shape" fill="#000000" transform="translate(42.666667, 42.666667)">
                            <path
                                d="M128,7.10542736e-15 C198.692448,7.10542736e-15 256,57.307552 256,128 C256,140.931179 254.082471,153.414494 250.516246,165.181113 L384,298.666667 C407.564149,322.230816 407.564149,360.435851 384,384 C360.435851,407.564149 322.230816,407.564149 298.666667,384 L165.181113,250.516246 C153.414494,254.082471 140.931179,256 128,256 C57.307552,256 7.10542736e-15,198.692448 7.10542736e-15,128 C7.10542736e-15,114.357909 2.13416363,101.214278 6.08683609,88.884763 L66.6347809,149.333333 L126.649,129.346 L129.329,126.666 L149.333333,66.7080586 L88.7145729,6.14152881 C101.0933,2.15385405 114.29512,7.10542736e-15 128,7.10542736e-15 Z">

                            </path>
                        </g>
                    </g>
                </svg>

            </div>
            <h3>Kısa bir süre sonra geri döneceğiz!</h3>
            <p>Site şu anda bakım nedeniyle kapalı. Lütfen daha sonra tekrar kontrol edin.</p>
        </div>
    );
}

function App() {
    // Maintenance mode state
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

    return (
        <div>
            <Routes>
                {isMaintenanceMode ? (
                    // Redirect all routes to the maintenance page
                    <Route path="*" element={<Navigate to="/maintenance"/>}/>
                ) : (
                    <>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/contact-form" element={<ContactForm/>}/>
                        <Route path="/career-form" element={<CareerForm/>}/>
                        <Route path="/activity-registration-form" element={<ActivityRegistrationForm/>}/>
                        <Route path="/success" element={<Success/>}/>
                        <Route path="/camp-register-success" element={<CampRegisterSuccess/>}/>
                        <Route path="/failed" element={<Failed/>}/>
                    </>
                )}
                {/* Maintenance route */}
                <Route path="/maintenance" element={<Maintenance/>}/>
            </Routes>
        </div>
    );
}

export default App;
