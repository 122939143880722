export class MessageData {
    constructor(adminFrom,
                userFrom,
                adminEmail,
                userEmail,
                adminSubject,
                userSubject,
                adminHtmlTemplate,
                userHtmlTemplate,
                contactFirstName,
                contactLastName,
                contactEmail,
                contactPhone,
                contactMessageTitle,
                contactMessage) {
        this.adminFrom = adminFrom;
        this.userFrom = userFrom;
        this.adminEmail = adminEmail;
        this.userEmail = userEmail;
        this.adminSubject = adminSubject;
        this.userSubject = userSubject;
        this.adminHtmlTemplate = adminHtmlTemplate;
        this.userHtmlTemplate = userHtmlTemplate;
        this.contactFirstName = contactFirstName;
        this.contactLastName = contactLastName;
        this.contactEmail = contactEmail;
        this.contactPhone = contactPhone;
        this.contactMessageTitle = contactMessageTitle;
        this.contactMessage = contactMessage;
    }
}